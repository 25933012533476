import React, { useEffect, useState } from 'react';
import config from '../../config';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import {Button, Modal, AspectRatioWrapper,ResponsiveImage} from '../../components';
import { Carousel } from 'react-responsive-carousel';

const SectionGallery = props => {
  const {
    listing,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onUpdateCurrentUserProfile,
  } = props;
  const images = listing.images;
  const { variantPrefix } = config.listing;
  const imageVariants = ['embryoStyle', 'embryoStyle2x', 'embryoStyle4x', 'embryoStyle6x'];
  const thumbnailVariants = [`embryoStyle`, `embryoStyle2x`, `embryoStyle4x`];
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
  //const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  /* const [isListingFavourited, setIsListingFavourited] = useState(false); */

  useEffect(() => {
    setMounted(true);
  }, []);

  const id = listing?.id?.uuid;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  // const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

      const favModal = (
        onManageDisableScrolling? (
    <Modal
      id="authenticateUserPopup"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h2 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationModal.title" />
      </h2>
      <div className={css.modalDescription}>
        <p>
          <FormattedMessage id="AuthenticationModal.Description" />
        </p>
            <Button onClick={() => {
            setIsModalOpen(false);
              window.location.href = "/login";
            }}>
          <FormattedMessage id="AuthenticationModal.Button" />
        </Button>
      </div>
    </Modal>
          ) : null
      )

  let favouriteCSS = css.heartIcon;
      const favourites = currentUser?.attributes?.profile?.protectedData?.favouriteListings? currentUser.attributes.profile.protectedData.favouriteListings : [];

  if (authenticatedOnClientSide && favourites) {
        favouriteCSS = favourites.includes(id)? css.heartIconLiked : css.heartIcon
  }

      const addListingToCurrentUserFavourites = (e) => {
    e.preventDefault();

    if (authenticatedOnClientSide) {
      let newFavourites = favourites;
      let protectedData = currentUser.attributes.profile.protectedData;

      // if favouriteListings is empty or undefined, add the listing to it
      if (!favourites && !favourites.includes(id)) {
        newFavourites = [...favourites, listing.id.uuid];
            protectedData = {...protectedData, favouriteListings: newFavourites};
      } else if (!favourites.includes(id)) {
        newFavourites = [...favourites, listing.id.uuid];
            protectedData = {...protectedData, favouriteListings: newFavourites};
      } else {
        newFavourites = favourites.filter(f => f !== listing.id.uuid);
            protectedData = {...protectedData, favouriteListings: newFavourites};
      }

          onUpdateCurrentUserProfile({ protectedData});
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
      }

  return (
    <div className={css.gallery}>
      {images.length > 1 ? (
        isMobile ? (
          <Carousel>
            {images.map((image, i) => (
              <ResponsiveImage
                key={i}
                rootClassName={css.item}
                image={image}
                alt={'listing image ' + i}
                variants={imageVariants}
              />
            ))}
          </Carousel>
        ) : (
          images.map((image, i) => (
            <ResponsiveImage
              key={i}
              rootClassName={css.item}
              image={image}
              alt={"listing image " +i}
              variants={imageVariants}
            />
          ))
        )
      ) : (
        <ResponsiveImage
          rootClassName={css.item}
          image={images[0]}
          alt={"listing image"}
          variants={imageVariants}
        />
      )}
      {favModal}
      <div className={css.listingLikeIcon}>
        <svg className={`${favouriteCSS} ${css.likeDesktop}`} onClick={addListingToCurrentUserFavourites.bind(this)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
      </div>
    </div>
  );
};

export default SectionGallery;
