import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMobile = props => {
  const { description, listingTitle } = props;
  return description ? (
    <div className={css.sectionDescriptionMobile}>
      <p className={css.descriptionTitleMobile}>
        <FormattedMessage id="ListingPage.descriptionTitle" values={{ listingTitle }} />
      </p>
      <p className={css.descriptionMobile}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMobile;